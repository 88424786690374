import * as React from "react";
import { forwardRef } from "react";
import {  MenuItemLink } from 'react-admin';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';


const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to="/"
        primaryText="Dashboard"
        leftIcon={<DesktopMacIcon />}
        onClick={onClick} // close the menu on click
    />
  ));

  export default ConfigurationMenu;