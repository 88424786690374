import { defaultTheme } from "react-admin";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";
import merge from "lodash/merge";

const palette = createPalette(
    merge({}, defaultTheme.palette, {
      primary: {
        main: "#0079fa",
      },
      secondary: {
        light: '#0066ff',
        main: '#0079fa',
        contrastText: '#ffffff',
      },contrastThreshold: 3,
      tonalOffset: 0.2,
      background: {
        paper: '#fff',
        default: "#fafafa"
      },
    })
  );


const rawTheme = {
    palette,
      typography: {
          fontFamily: 'Inter, -apple-system, system-ui, system-ui, "Segoe UI", Roboto',
          fontSize: 14,
          fontWeightMedium: 600,
          fontWeightLight: 400,
          fontWeightRegular: 500,
      },
};

const MyTheme = createMuiTheme(
    merge({}, defaultTheme, rawTheme)
);

export default MyTheme;

