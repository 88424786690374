import * as React from "react";
import PropTypes from 'prop-types';

const iframeStyle = {
    height: '100%',
    width: '100%',
    border: 0,
    top: 0,
    left: 0,
    position: 'absolute',
  };

const divStyle = {
    paddingTop: '56.25%',
    position: 'relative',
    overflow: 'hidden',
    height: 'auto',
  };

const IFrameField = ({ source, record = {} }) => {
  return (
  <div style={divStyle}>
    <iframe title="app-iframe"
      src={record[source]}
      display="initial"
      style={iframeStyle}>
        Loading please wait...
      </iframe>
    </div>
  );
};

IFrameField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default IFrameField;