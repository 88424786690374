import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';


const Dashboard = ({ onMenuClick, logout }) => {

    const divStyle = {
        paddingTop: '30px',
        width: '600px'
    };

    return (  
    <div style={divStyle}> 
    <Card>
        <CardHeader title="Welcome to the GOCare Applications Portal" />
        <CardContent>
            <img src="https://storage.googleapis.com/gocare-connect-kzwkw7hbnirrefa48tgba/gocare_846cde07b1/gocare_846cde07b1.png" 
                alt="GOCare Portal" />
        </CardContent>
    </Card>
    </div>
    );
}


export default Dashboard;