import * as React from "react";
import { UserMenu} from 'react-admin';
import ConfigurationMenu from './configurationMenu';

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

export default MyUserMenu;