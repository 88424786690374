import React from 'react';
import { List, Datagrid, TextField, SimpleShowLayout, ShowButton, useGetIdentity } from 'react-admin';

import IFrameField from './Iframer';
import MyShow from './MyShow'; 
import LaunchIcon from '@material-ui/icons/Launch';
import { Typography } from '@material-ui/core';

import Cookies from './helpers/Cookies'

const MyIframe= props => {
    return <IFrameField {...props} />;
};

const Aside = () => {
    const { identity, loading: identityLoading } = useGetIdentity();

    if (identityLoading) return <>Loading...</>;

    return (
    <div style={{ width: 240, margin: '1.4em' }}>
        <Typography variant="h6">Hi {`${identity.fullName}`}!</Typography>
        <Typography variant="body2" color="primary">
            Welcome to your GOCare Portal. Check back often for new apps and features!
        </Typography>
    </div>
    );
}


export const AppsList = props => {

    const divStyle = {
        paddingTop: '30px',
        width: '94%'
    };

    const role = Cookies.getCookie('gcPartner');

    return(
        <div style={divStyle}> 
            <List component="div" aside={<Aside />} title="GOCare Applications"  actions={null} filter={{ active: true, app_company: role }} bulkActionButtons={false} {...props}>
                <Datagrid rowClick="show" isRowSelectable={record => record.id < 1}>
                    <TextField label="App Name" source="app_name" />
                    <ShowButton label="Launch App" icon={<LaunchIcon />} />
                </Datagrid>
            </List>
        </div>
    );

}


//Custom Show component to display iframed Retool apps
export const AppsShow = props => {
    return (
    <MyShow {...props}>
        <SimpleShowLayout>
            <MyIframe label="App Name" source="app_url"/>
        </SimpleShowLayout>
    </MyShow>
    );
}

