import * as React from "react";
import { Admin, Resource, AppBar, Layout } from 'react-admin';
import simpleRestProvider from './ra-strapi-rest';
import authProvider from './authProvider';
import httpClient from './helpers/HttpClient'

import Dashboard from './Dashboard';
import MyMenu from './Menu';
import MyUserMenu from './userMenu';
import MyTheme from './MyTheme';

import LoginPage from './LoginPage';

import customRoutes from './customRoutes';

import HttpsIcon from '@material-ui/icons/Https';
import AppsIcon from '@material-ui/icons/Apps';

import { AppsList } from './devApps';
import { AppsShow }  from './devApps';

const dataProvider = simpleRestProvider('https://connect-api.gocare.io', httpClient);

//const MyLoginPage = () => <Login backgroundImage="https://storage.googleapis.com/gocare-connect-kzwkw7hbnirrefa48tgba/beam_1210d10669/beam_1210d10669.png" />;

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;
const MyLayout = props => <Layout {...props} appBar={MyAppBar} menu={MyMenu}/>;

const App = () => (
    <React.Fragment>
      <Admin customRoutes={customRoutes} loginPage={LoginPage} layout={MyLayout} title="GOCare Admin Portal" theme={MyTheme} dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider}>
        {permissions => [
          permissions === 'Supervisor'
          ? <Resource name="admin-apps" icon={HttpsIcon} options={{ label: 'Admin Apps' }} show={AppsShow} list={AppsList} />
          : null,
        //  permissions === 'Dialer' || permissions === 'Supervisor'
          permissions === 'Dialer'
          ? <Resource name="outbound-messagings" icon={HttpsIcon} options={{ label: 'Outbound Messaging' }} show={AppsShow} list={AppsList} />
          : null,
          permissions === 'Supervisor' || permissions === 'Agent'
          ? <Resource name="user-apps" icon={HttpsIcon} options={{ label: 'User Apps' }} show={AppsShow} list={AppsList} />
          : null
        ]}
      </Admin>
    </React.Fragment>
  );

export default App;