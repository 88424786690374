import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { Typography } from '@material-ui/core';

const ResetPassword = () => (
    <Card>
        <Title title="Reset Password" />
        <CardContent>
            <Typography variant="body2" color="primary">
                Here you can reset your password
            </Typography>
        </CardContent>
    </Card>
);

export default ResetPassword;