import Cookies from './helpers/Cookies'

export default {

    login: ({ username, password }) => {
        const identifier = username // strapi expects 'identifier' and not 'username'
        const request = new Request('https://connect-api.gocare.io/auth/local', {
            method: 'POST',
            body: JSON.stringify({ identifier, password }),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    //throw new Error(response.statusText);
                    throw new Error('Incorrect Login. Please try again.');
                }
                return response.json();
            })
            .then(response => {
                Cookies.setCookie('gcPortalToken', response.jwt, 1);
                Cookies.setCookie('gcPortalRole', response.user.role.name, 1);
                Cookies.setCookie('gcPartner', response.user.company, 1);
                localStorage.setItem('id_content', JSON.stringify(response.user));
            });
    },

    logout: () => {
        Cookies.deleteCookie('gcPortalToken');
        Cookies.deleteCookie('gcPortalRole');
        Cookies.deleteCookie('gcPartner');
        localStorage.removeItem('id_content');
        return Promise.resolve();
    },

    checkAuth: () => {
        return Cookies.getCookie('gcPortalToken') ? Promise.resolve() : Promise.reject();
    },

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            Cookies.deleteCookie('gcPortalToken');
            Cookies.deleteCookie('gcPortalRole');
            Cookies.deleteCookie('gcPartner');
            localStorage.removeItem('id_content');
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const role = Cookies.getCookie('gcPortalRole');
        return role ? Promise.resolve(role) : Promise.reject();
    },

    getIdentity: () => {
        const id_Content = JSON.parse(localStorage.getItem('id_content'));
        const id = id_Content.id;
        const fullName = id_Content.fullName;
        if(id_Content.avatar) {
            const avatar = id_Content.avatar.url;
            return { id, fullName, avatar };
        } else {
            const avatar = "https://storage.googleapis.com/gocare-connect-kzwkw7hbnirrefa48tgba/beam_square_4bb3245082/beam_square_4bb3245082.png";
            return { id, fullName, avatar };
        }
    }
}

